import React from "react"

import EventTemplate from "../../components/templates/EventTemplate"
import PosterImage from "../../components/shinel-poster"
import OutlinkText from "../../components/molecules/OutlinkText"
import ReserveButton from "../../components/molecules/ReserveButton"

const IndexPage = () => (
  <EventTemplate
    title={"몸쓰기: 하바들의 여행지도"}
    description={`낯선 곳을 여행하기 위해 우리는 언제나 지도를 꺼내 들고 더듬더듬 나아갑니다. 아무것도 모를 수가 없는 요즘 시대에는 지도에 나오지 않는 곳을 여행하고 싶다는 낭만적인 상상을 해보기도 하지요.

〈하바 이야기〉의 하바들은 지도 없이 낯선 땅에서의 여행을 시작합니다. 너무 내밀하여 아무도 보여줄 수 없는 곳에서 길을 찾고, 누구나 오가는 드러내어진 장소에서 길을 잃기도 하면서요. 〈몸쓰기: 하바들의 여행지도〉 워크샵 에서는 하바들의 방법을 빌려 자신 안팎의 길과 장소들을 찾아내고 언어로 구체화 시키는 과정을 통해 자신만의 여행 지도를 완성해 가는 것을 목표로 합니다.`}
    image={`/write-body.jpeg`}
  >
    <PosterImage
      filename="write-body.jpeg"
      style={{ margin: `0 auto`, maxWidth: 500 }}
    />
    <br />
    <p>
      낯선 곳을 여행하기 위해 우리는 언제나 지도를 꺼내 들고 더듬더듬
      나아갑니다. 아무것도 모를 수가 없는 요즘 시대에는 지도에 나오지 않는 곳을
      여행하고 싶다는 낭만적인 상상을 해보기도 하지요. 『하바 이야기』의
      하바들은 지도 없이 낯선 땅에서의 여행을 시작합니다. 너무 내밀하여 아무도
      보여줄 수 없는 곳에서 길을 찾고, 누구나 오가는 드러내어진 장소에서 길을
      잃기도 하면서요. 〈몸쓰기: 하바들의 여행지도〉 워크샵 에서는 하바들의
      방법을 빌려 자신 안팎의 길과 장소들을 찾아내고 언어로 구체화 시키는 과정을
      통해 자신만의 여행 지도를 완성해 가는 것을 목표로 합니다.
    </p>
    <hr />
    <p>
      Day 1: 뼈와 호흡과 의지
      <br />
      몸 안에 감추어진 공간을 찾아 나섭니다. 내밀하여 숨겨진 공간들이 살아날때
      이곳의 제 주인들이 나타납니다.
      <br />
      <br />
      Day 2: 표면과 만남, 그리고 마땅한 조율
      <br />
      몸 표면과 바깥에 자리한 자신의 공간을 인지하고 타인과 만납니다. 각자의
      공간이 모일때 발생하는 사건들을 관찰해 봅니다.
      <br />
    </p>
    <p>
      <strong>
        • 일시: 2025. 3.30 (일) / 4.6 (일) 14:00-16:30 (약 150분)
        <br />
        • 장소: 서점극장 라블레
        <br />
        • 진행: 최승윤
        <br />
        • 인원: 청소년 6명
        <br />
        • 참가비: 8만원 (『하바 이야기』 포함)
        <br />
        • 준비물: 움직이기 편한 복장, 필기구와 노트, 마실 물.
        <br />• 문의:&nbsp;
        <OutlinkText href="mailto:mail@rabelais.kr">
          mail@rabelais.kr
        </OutlinkText>
      </strong>
    </p>
    <hr />
    <p>
      강사소개
      <br />
      <br />
      최승윤
      <br />
      <br />
      안무가, 무용수, 배우 등 몸을 매체로 삼아 예술활동을 합니다.
      <br />
      <br />
      «아르코 차세대 안무가 2012», «한팩라이징스타 2012», «the swedish arts
      grants committee 2013» 입주작가, 국립현대무용단 «안무랩 2014»,
      유망예술지원사업 «MAP 2017-2018» 등의 사업에 선정되었으며 대표작으로는
      «사라지기위한 시간», «Forget 츄, but I will archive you», «120분: I by you
      by everybody» 등이 있습니다.
      <br />
      <br />
      또한 장편영화 «라이스보이 슬립스 2023» 주연, 장편영화 «I BY YOU BY
      EVERYBODY 2019» 공동연출, 시리즈 «DXYZ 2018-2020» 주연 맡았습니다.
    </p>
    <br />
    <hr />
    <br />
    <ReserveButton link={`https://forms.gle/MLxPWj5sBxHuF4wn7`} />
    <br />
  </EventTemplate>
)

export default IndexPage
